import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OrderContainer from '../../common/OrderContainer';
import Step3 from '../../../components/Step3v1';
import { trackEvent } from '../../../services/analytics';
import featureSwitch from '../../../services/featureSwitch';
import { hasCurtains, hasClothes } from '../../../core/services/order';

class FabricsContainer extends Component {
  constructor() {
    super();
    this.toggleFabric = this.toggleFabric.bind(this);
    this.selectNone = this.selectNone.bind(this);
    this.setThreadColor = this.setThreadColor.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  setThreadColor(threadColor) {
    trackEvent('set-thread-color', threadColor);
    this.props.dispatch({ type: 'ORDER.SET_THREAD_COLOR', threadColor });
  }

  toggleFabric(fabric) {
    trackEvent('toggle-matiere', fabric);
    this.props.dispatch({ type: 'ORDER.TOGGLE_FABRIC', fabric });
  }

  selectNone() {
    trackEvent('toggle-matiere', 'aucune');
    this.props.dispatch({ type: 'ORDER.SELECT_NONE_FABRIC' });
  }

  render() {
    const { clothes } = this.props;
    const showCurtainsFabrics = !featureSwitch('step3_noCurtains') &&
      (featureSwitch('step3_curtainsOnly') || hasCurtains({ clothes }) ||
      (!clothes || clothes.length === 0));
    const showClothesFabrics = hasClothes({ clothes }) ||
      (!clothes || clothes.length === 0);
    return (
      <OrderContainer>
        <Step3
          showClothesFabrics={showClothesFabrics}
          showCurtainsFabrics={showCurtainsFabrics}
          selectedFabrics={this.props.selectedFabrics}
          toggleFabric={this.toggleFabric}
          selectNone={this.selectNone}
          threadColor={this.props.threadColor}
          setThreadColor={this.setThreadColor}
          nextStep={this.props.nextStep}
        />
      </OrderContainer>
    );
  }
}

FabricsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedFabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  threadColor: PropTypes.string.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nextStep: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  selectedFabrics: state.order.fabrics,
  threadColor: state.order.threadColor,
  clothes: state.order.clothes,
});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(FabricsContainer);
