import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../styledComponents';
import featureSwitch from '../../services/featureSwitch';
import routesMap from '../../Routes';

const ProgressBarContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: space-around;
  margin: 20px 0px 30px 0px;
  padding: 0 80px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 0px;
  }
`;

const Progress = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  text-align center;
  width: 33%;
  opacity: ${props => props.isActive ? '1' : '0.5'};
  cursor: pointer;
  ${props => props.isActive ? 'transform: scale(1.1)' : ''};
`;

const ProgressDot = styled.span`
  display: inline-block;
  text-align: center;
  font-size: 13px;
  background: white;
  border: 1px solid ${({ isActive }) => isActive ? colors.navy : '#333'};
  padding: 5px 9px;
  border-radius: 50%;
  margin-bottom: 4px;
  width: 7px;
`;

const ProgressText = styled.div`
  text-align: center;
  font-size: 13px;
`;

const steps = [
  { id: 1, name: 'vêtements', link: routesMap.Step1.url },
  { id: 2, name: 'retouches', link: routesMap.EstimationVariantAlterations.url },
  { id: 3, name: 'RDV', link: routesMap.EstimationVariantRdv.url },
  { id: 4, name: 'coordonnées', link: routesMap.EstimationVariantInfos.url },
];

const ProgressBar = ({ step }) =>
  featureSwitch('progressBar_hide') ? null : (
    <ProgressBarContainer>
      {steps.map(stepIt =>
        <Progress
          key={stepIt.id}
          isActive={step === stepIt.id}
          onClick={() => navigate(stepIt.link)}
        >
          <ProgressDot isActive={step === stepIt.id}>
            {stepIt.id}
          </ProgressDot>
          <ProgressText>
            {stepIt.name}
          </ProgressText>
        </Progress>,
      )}
    </ProgressBarContainer>
  );

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProgressBar;
